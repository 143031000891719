import React from "react";
import "./benefits.scss";
import sideImg from './assets/side-img.webp';
import icon1 from './assets/icon1.webp';
import icon2 from './assets/icon2.webp';
import icon3 from './assets/icon3.webp';
import icon4 from './assets/icon4.webp';
import icon5 from './assets/icon5.webp';
import icon6 from './assets/icon6.webp';
import icon7 from './assets/icon7.webp';
import icon8 from './assets/icon8.webp';

const Benefits = () => {

    const lst = [
        {
            img: icon1,
            title: "Streamlined Launch Process",
            content: "Launch your token in days instead of weeks with our automated smart contract generation and deployment system. Our platform handles technical complexities while giving you full control over your token's parameters."
        },
        {
            img: icon2,
            title: "Comprehensive Security",
            content: "Benefit from automated security audits, multi-signature protection, and continuous monitoring. Our platform ensures your token launch meets the highest security standards while maintaining compliance with regulatory requirements."
        },
        {
            img: icon3,
            title: "Marketing and Community Tools",
            content: "Access built-in marketing tools, community management features, and analytics dashboards. Generate buzz around your project and engage with potential investors through our integrated communication channels."
        },
        {
            img: icon4,
            title: "Post-Launch Support",
            content: "Receive ongoing support with liquidity management, exchange listings, and community growth. Our platform provides the tools and guidance needed for sustainable project development after the initial token launch."
        },
        {
            img: icon5,
            title: "Verified Projects",
            content: "Every project undergoes thorough verification, including team KYC and smart contract audits. Invest with confidence knowing that each token launch meets our stringent security and legitimacy requirements."
        },
        {
            img: icon6,
            title: "Easy Participation",
            content: "Participate in token presales through an intuitive interface with integrated wallet support. Our platform streamlines the investment process while ensuring secure transaction handling."
        },
        {
            img: icon7,
            title: "Portfolio Management",
            content: "Track your investments, monitor token performance, and manage vesting schedules through a comprehensive dashboard. Access real-time analytics and project updates to make informed investment decisions."
        },
        {
            img: icon8,
            title: "Staking Opportunities",
            content: "Earn additional rewards through staking capabilities and early participation bonuses. Benefit from long-term growth potential through careful project curation and ongoing platform support."
        }
    ]

    return (
        <>
            <section className="benesSec">
                <div className="container">
                    <div>
                        <h2>Benefits of Blockwave Platform</h2>
                        <div className="mt-4">
                            <div className="row">
                                <span>For Token Issuers </span>
                                {lst.slice(0, 4).map((item, i) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="beneLst" key={i}>
                                                <img src={item.img} className="img-fluid" />
                                                <p className="beneLstTlt">{item.title}</p>
                                                <p className="beneLstCnt">{item.content}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="row mt-5">
                                <span>For Investors </span>
                                {lst.slice(4, 8).map((item, i) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="beneLst" key={i}>
                                                <img src={item.img} className="img-fluid" />
                                                <p className="beneLstTlt">{item.title}</p>
                                                <p className="beneLstCnt">{item.content}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Benefits;
