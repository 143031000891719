import React from "react";
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

const Faq = () => {
  const faqData = [
    {
      question: "What is an ICO platform?",
      answer: "An ICO (Initial Coin Offering) platform is a digital solution that helps blockchain projects raise capital by issuing tokens to investors. It streamlines token creation, distribution, and fundraising management."
    },
    {
      question: "Why choose ICO for fundraising?",
      answer: "ICO provides a decentralized way for startups to secure funding from global investors without intermediaries. It ensures faster transactions, increased liquidity, and broader accessibility compared to traditional fundraising methods."
    },
    {
      question: "What is an ICO listing?",
      answer: "An ICO listing is the process of featuring a new token sale on dedicated platforms or directories. These listings provide investors with key details like project vision, tokenomics, and timelines, helping them make informed investment decisions."
    },
    {
      question: "What is the best website for ICO listings?",
      answer: "Blockwave is the go-to platform for ICO listings, offering real-time updates, expert insights, and in-depth project analysis to help investors and startups connect seamlessly."
    },
    {
      question: "Is investing in ICOs profitable?",
      answer: "ICO investments have the potential for high returns but come with risks. Profitability depends on factors such as the project's credibility, market demand, and execution. Investors should conduct thorough research before participating."
    }
  ];

  return (
    <div className="FAQSec pt-lg-5 ">
      <div className="container">
        <h3>Frequently Asked Questions</h3>
        <Accordion>
          {faqData.map((faqItem, index) => (
            <AccordionItem key={index} header={faqItem.question}>
              <div dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

Faq.propTypes = {
  homepagedata: PropTypes.object.isRequired,
};

export default Faq;
