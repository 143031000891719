import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer12/Footer";
import { CONST } from "../../config";
import Helper from "../../common/Helper";
import ScaleLoader from "react-spinners/ScaleLoader";
import Banner from "./Banner/Banner";
import Platform from "./Platform/Platform";
import Features from "./Features/Features";
import Benefits from "./Benefits/Benefits";
import NativeToken from "./NativeToken/NativeToken";
import Roadmap from "./Roadmap/Roadmap";
import Launch from "./Launch/Launch";
import { Helmet } from 'react-helmet';
import Faq from "./FAQ";


const HomeUpdate = () => {
    useEffect(() => {
        fetchData()
    }, [])

    const [homepagedata, setHomepagedata] = useState([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState('')
    let color = "#ffffff";


    const fetchData = async () => {
        setLoading(true);
        let getData = await Helper.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/yTwTc17UCDnd2lmvB2QK`);

        if (getData?.status) {
            setHomepagedata(getData?.data[0])
            setCount(getData?.count)
        }
        setLoading(false)

    }
    return (
        <>
            <Helmet>
                <title>BlockWave ICO Platform | Leading ICO Listing & Token Launch Solutions</title>
                <meta name="description" content="BlockWave, the premier ICO platform offering comprehensive ICO listing services and token launch support to boost your crypto project's success." />
                <meta name="keywords" content="ICO platform, ICO listing, BlockWave, token launch, crypto ICO, blockchain fundraising, ICO marketing, crypto listing" />
            </Helmet>
            <div className={loading ? "spinner" : "d-none"}>

                <ScaleLoader color={color} size={50} />
            </div>
            <Header />
            <Banner />
            <Platform />
            <Features />
            <Benefits />
            <NativeToken />
            <Roadmap />
            <Launch />
            <div id="faq">
                <Faq />
            </div>

            <Footer />
        </>
    );
}

export default HomeUpdate;
