import React from "react";
import "./banner.scss";
import sideImg from './assets/side-img.webp';
import { useNavigate } from "react-router-dom";

const Banner = () => {
    const navigate = useNavigate();
    
        const handleClick = () => {
            navigate('/buytoken');
        };

    return (
        <>
            <section className="bnrSec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div>
                                <span>Blockwave</span>
                                <h1>Launch and Invest in 
                                ICOs with Ease</h1>
                                <p>Revolutionize your blockchain investment journey with the most comprehensive, secure, and user-friendly ICO launch platform. Blockwave empowers projects to raise capital and investors to discover groundbreaking blockchain opportunities with unprecedented ease and transparency.</p>
                                <div className="mt-5">
                                    <a className="bnrBtn" onClick={handleClick}>Buy Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <img src={sideImg} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;
