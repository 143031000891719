import React from "react";
import "./roadmap.scss";
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';


const Roadmap = () => {

    return (
        <>
            <section className="roadsSec">
                <div className="container">
                    <div>
                        <h2>ICO Launch Process and Roadmap</h2>
                        <span>Launch Process</span>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="roadLst">
                                        <img src={icon1} className="img-fluid" />
                                        <p className="roadLstTlt">Project Application and Setup (Week 1)</p>
                                        <ul>
                                            <li>Project submission and initial review</li>
                                            <li>Team KYC verification</li>
                                            <li>Smart contract customization and 
                                            generation</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="roadLst">
                                        <img src={icon2} className="img-fluid" />
                                        <p className="roadLstTlt">Pre-Launch Preparation (Week 2)</p>
                                        <ul>
                                            <li>Security audit completion</li>
                                            <li>Marketing campaign initiation</li>
                                            <li>Community building activities</li>
                                            <li>Whitelist setup and management</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="roadLst">
                                        <img src={icon3} className="img-fluid" />
                                        <p className="roadLstTlt">Token Launch (Week 3)</p>
                                        <ul>
                                            <li>Presale execution</li>
                                            <li>Token distribution</li>
                                            <li>Liquidity pool creation</li>
                                            <li>Exchange listing coordination</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Roadmap;
