import React from "react";
import "./launch.scss";
import img from './assets/img.svg';



const Launch = () => {

    return (
        <>
            <section className="launchSec">
                <div className="container-fluid p-0 m-0">
                    <div className="d-lg-block d-none">
                        <img src={img} className="img-fluid"/>
                    </div>

                    <div className="d-lg-none d-block">
                        <p>Launch your ICO or explore promising investment opportunities with Blockwave</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Launch;
