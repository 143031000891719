import React from "react";
import "./features.scss";
import sideImg from './assets/side-img.webp';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';

const Features = () => {

    const lst = [
        {
            img: icon1,
            title: "Token Creation and Management",
            content: "Our advanced token creation engine enables customizable token parameters, automated smart contract generation, and built-in security audits. Projects can define their tokenomics, vesting schedules, and distribution mechanisms through an intuitive interface."
        },
        {
            img: icon2,
            title: "Presale Management",
            content: "Comprehensive tools for managing token presales include whitelist management, dynamic pricing mechanisms, and automated distribution systems. Real-time analytics provide insights into sale progress and participant engagement."
        },
        {
            img: icon3,
            title: "Security Infrastructure",
            content: "Multi-signature wallet integration, automated security audits, and continuous transaction monitoring protect both projects and investors. Emergency pause functionality and anti-bot measures ensure safe and fair token launches."
        },
        {
            img: icon4,
            title: "Post-Launch Support",
            content: "Projects benefit from liquidity management tools, exchange listing assistance, and marketing support after launch. Our platform provides ongoing analytics and community management tools to support sustainable growth."
        }
    ]

    return (
        <>
            <section className="featsSec">
                <div className="container">
                    <div>
                        <h2>Features of Blockwave Platform</h2>
                        <div className="mt-4">
                            <div className="row align-items-end">
                                <div className="col-lg-4 col-sm-6">
                                    {lst.slice(0, 2).map((item, i) => {
                                        return (
                                            <div className="featLst" key={i}>
                                                <img src={item.img} className="img-fluid" />
                                                <p className="featLstTlt">{item.title}</p>
                                                <p className="featLstCnt">{item.content}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col-lg-4 order-lg-0 order-last">
                                    <div className="mt-lg-0 mt-5">
                                        <img src={sideImg} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    {lst.slice(2,4).map((item, i) => {
                                        return (
                                            <div className="featLst" key={i}>
                                                <img src={item.img} className="img-fluid" />
                                                <p className="featLstTlt">{item.title}</p>
                                                <p className="featLstCnt">{item.content}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features;
