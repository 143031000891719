import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Banner from "./components/Banner/Banner";
import Overview from "./components/Overview";
import Tokenomics from "./components/Tokenomics";
import Roadmap from "./components/Roadmap";
import WhitePaper from "./components/Whitepaper";
import Faq from "./components/FAQ";
import Footer from "../../common/Footer12/Footer";
import { CONST } from "../../config";
import Helper from "../../common/Helper";
import ScaleLoader from "react-spinners/ScaleLoader";
import Invest from "./components/Invest";
import Features from "./components/Features";
import Future from "./components/Future";
import { Helmet } from 'react-helmet';

const Home = () => {
    useEffect(() => {
        fetchData()
    }, [])

    const [homepagedata, setHomepagedata] = useState([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState('')
    let color = "#ffffff";


    const fetchData = async () => {
        setLoading(true);
     let getData = await Helper.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/yTwTc17UCDnd2lmvB2QK`);
      
        if (getData?.status) {
            setHomepagedata(getData?.data[0])
            setCount(getData?.count)
        }
        setLoading(false)

    }
    return (
        <>
        <Helmet>
        <title>Blockwave - Revolutionizing the Crypto Landscape with Next-Gen DeFi Token</title>
        <meta name="description" content="Blockwave Token (BWT) an innovative ERC-20 token on the Ethereum network. With seamless transactions, staking rewards, and community governance, BWT aims to build a more transparent and efficient blockchain ecosystem for users worldwide." />
        <meta name="keywords" content="blockwave token, blockwave, defi token, token presale, crypto tokens, defi tokens presale, token launch, token investments" />
      </Helmet>
            <div className={loading ? "spinner" : "d-none"}>
                <ScaleLoader color={color} size={50} />
            </div>
            <Header />
            <div className="MainBg">
                <div className="cntld">
                    <Banner homepagedata={homepagedata} />
                    <div id="overview">
                        <Overview homepagedata={homepagedata} />
                    </div>
                    <div id="invest">
                        <Invest homepagedata={homepagedata} />
                    </div>
                    <div id="features">
                        <Features homepagedata={homepagedata} />
                    </div>
                    <div id="tokenomics">
                        <Tokenomics homepagedata={homepagedata} count={count} />
                    </div>
                    <div id="roadmap">
                        <Roadmap homepagedata={homepagedata} />
                    </div>
                    <div id="future">
                        <Future homepagedata={homepagedata} />
                    </div>
                    <div id="whitepaper">
                        <WhitePaper homepagedata={homepagedata} />
                    </div>
                    <div id="faq">
                        <Faq homepagedata={homepagedata} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;
