import React, { useState, useEffect } from "react";
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import logoImg from '../../assets/images/logo.png';

const Header = () => {

    const [active, setActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const toggleMenu = () => setActive(!active);
    const toggle = () => setIsOpen(!isOpen);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleLinkClick = () => {
        scrollToTop();
        navigate('/bwt-token');
    };


    const pageTo = (section) => {
        navigate('/bwt-token');

        setTimeout(() => {
            const element = document.querySelector(section);
            if (element) {
                window.scrollBy({
                    top: element.getBoundingClientRect().top - 25,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };

    const handleClick = () => {
        navigate('/register');
    };
    return (
        <header id="homeheader" className={scroll ? "indHd hdrFd scroll" : "indHd hdrFd"}>
            <nav className={active ? "navbar navbar-expand-xl  sbMenu" : "navbar navbar-expand-xl "}>
                <div className="container NavBg px-3 py-2">
                    <Link className="navbar-brand" to="/" onClick={scrollToTop}>
                        <img src={logoImg} alt="logo" />
                    </Link>
                    <div className="hdNavMenu w-100">
                        <Collapse isOpen={isOpen} navbar className="CllpsMenu align-items-center justify-content-end">
                            <Nav navbar className="align-items-center">
                                <NavItem>
                                    <NavLink href="#" to="/" className="active" onClick={handleLinkClick}>Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://blockwaveprod.s3.amazonaws.com/Blockwavewhitepaper.pdf"

                                    >Whitepaper</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/bwt-token"

                                    >BWT Token</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/login">Login</NavLink>
                                </NavItem>
                                <NavItem>
                                    <div className="p-0">
                                        <button className={'commonbtn'} onClick={handleClick}>{'Register'}</button>

                                    </div>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                    <button
                        className="mobMenu d-xl-none"
                        style={{
                            all: 'unset', // Reset all styles
                            cursor: 'pointer', // Change cursor to pointer
                            background: 'transparent', // Transparent background
                        }}
                        onClick={toggleMenu}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                                toggleMenu();
                            }
                        }}
                    >
                        <button
                            className={active ? "smClose active" : "smClose"}
                            style={{
                                all: 'unset', // Reset all styles
                                cursor: 'pointer', // Change cursor to pointer
                                background: 'transparent', // Transparent background
                            }}
                            onClick={toggle}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === 'Space') {
                                    toggle();
                                }
                            }}
                        >
                            <svg id="closeicon" viewBox="0 0 800 600">
                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                <path d="M300,320 L460,320" id="middle"></path>
                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                            </svg>
                        </button>
                    </button>
                </div>
            </nav>
        </header>
    );
}

export default Header;

