import React from "react";
import "./native.scss";
import { useNavigate } from "react-router-dom";



const NativeToken = () => {
const navigate = useNavigate();
    
        const handleClick = () => {
            navigate('/bwt-token');
        };
    return (
        <>
            <section className="nativSec">
                <div className="container">
                    <div>
                        <h2 className="nativSecTlt">Blockwave - Native Token of the Platform</h2>
                        <p className="nativSecCnt">Blockwave Token (BWT) is a pioneering ERC-20 utility token built on the Ethereum network, designed to revolutionize decentralized finance (DeFi) and blockchain infrastructure. Serving as a multifunctional asset, BWT enables seamless transactions, robust staking mechanisms, and community-driven governance within its ecosystem. With its innovative approach, BWT aims to create a more inclusive, efficient, and transparent financial infrastructure powered by blockchain technology.</p>
                        <div className="mt-5 text-center">
                                    <a className="bnrBtn" onClick={handleClick}>Buy Now</a>
                                </div>
                        {/* <div className="nativeBg">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="nativeLst">
                                        <p className="nativeLstTlt">Utility and Governance</p>
                                        <ul>
                                            <li>Voting rights on platform upgrades and
                                                new features</li>
                                            <li>Priority access to new token launches</li>
                                            <li>Staking rewards from platform revenue</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="nativeLst">
                                        <p className="nativeLstTlt">Tokenomics</p>
                                        <p className="nativeLstCnt">BWT implements a deflationary model with systematic burning mechanisms to enhance long-term value. The total supply is capped at 100 billion tokens, with allocations carefully distributed across platform development, team vesting, and public sale.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="nativeLst">
                                        <p className="nativeLstTlt">Staking Benefits</p>
                                        <p className="nativeLstCnt">Stake BWT to earn platform fees and receive multiplier bonuses based on staking duration. Stakers gain enhanced voting power in  platform governance and exclusive access to premium features.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default NativeToken;
