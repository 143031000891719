import React from "react";
import "./platform.scss";
import sideImg from './assets/side-img.webp'

const Platform = () => {

    return (
        <>
            <section className="platSec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div>
                                <img src={sideImg} className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0 order-first">
                            <div>
                                <h2>Why Choose This Platform?</h2>
                                <p>Blockwave stands apart as the most comprehensive ICO platform in the cryptocurrency space. Our platform combines advanced technology with user-friendly interfaces, making token launches and investments accessible to everyone while maintaining institutional-grade security. Our platform offers automated smart contract generation, ensuring your token's foundation is secure and efficient. With real-time analytics, multi-layer security protocols, and dedicated support teams, we've created an ecosystem where both project creators and investors can operate with confidence. The platform's integrated KYC/AML systems and automated vesting mechanisms protect all participants while streamlining the investment process. By choosing Blockwave, you're not just getting a launch platform – you're joining a growing ecosystem that supports projects from inception through sustained growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Platform;
